* {
  padding: 0;
  margin: 0;
}

#video-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-color: black;
}

#video-wrapper video {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: black;
}

.text {
  color: white !important;
}

video {
  display: block;
}

#video {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#overlay-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

#sound-button-container {
  position: fixed;
  bottom: 0;
  right: 0;
}

#play-button-container {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

#main-play-container {
  width: 100%;
  height: 100%;
}

#content-container {
  height: auto;
  overflow: scroll;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

#full-video-button {
  position: absolute;
  bottom: 0;
}

#menu-bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#menu-bar-logo {
  margin: 0 15px;
  position: fixed;
}

#menu-button {
  position: fixed;
  right: 0;
  margin: 10px 0;
}

#vinyl-wrapper {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: auto;
  flex-wrap: wrap;
}

#merch-container {
  margin: 20px 0 300px 0;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.vinyl-button {
  margin: 20px 40px !important;
}

#schedule-container {
  margin: 100px 0;
  width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
}

#plugin-container {
  width: 100%;
  margin: 40px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

#schedule-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-width: 800px;
}

#video-overlay-logo {
  margin: 0;
  position: absolute;
  width: 30%;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
